import { useGetProjectApiKey } from "@custom-hooks/api-key";
import { useGetProjectDatabases } from "@custom-hooks/databases";
import { useGetProjectUsers } from "@custom-hooks/project-user/hooks/useGetProjectUsers";
import { useGetUserProjects } from "@custom-hooks/projects";
import { useGetProjectInfo } from "@custom-hooks/useApi";
import { ApiKey } from "@data-types/api-key-types";
import { Database } from "@data-types/databases-types";
import { ProjectUser } from "@data-types/project-user-types";
import { Project } from "@data-types/projects-types";
import { useMemo, useState } from "react";

/**
 * Custom hook to build a connection string for a specific project by its ID.
 *
 * @param {string} projectId - The ID of the project for which to fetch data and build the connection string.
 * @param {boolean} isDialogOpen - Indicates whether the dialog is open.
 *
 * @returns {object} - The result object containing the following grouped properties:
 * @property {object} project - Contains project options and the selected project.
 * @property {object} project.options - An object containing available project options.
 * @property {Record<string, Project>} project.options.options - A map of project IDs to their details.
 * @property {string[]} project.options.sortedKeys - A sorted list of project IDs.
 * @property {string} project.selected - The currently selected project ID.
 * @property {function} project.setSelected - Function to update the selected project.
 * @property {boolean} project.isError - Indicates if there was an error fetching project data.
 * @property {boolean} project.showLoader - Indicates if the project data fetch operation is in progress.
 * @property {boolean} project.isEmpty - Indicates if no projects are available for the user.
 *
 * @property {object} database - Contains information about available databases and the selected database.
 * @property {object} database.options - An object containing available database options.
 * @property {Record<string, Database>} database.options.options - A map of database names to their details.
 * @property {string[]} database.options.sortedKeys - A sorted list of database names.
 * @property {string | undefined} database.selected - The currently selected database name.
 * @property {function} database.setSelected - Function to update the selected database.
 * @property {boolean} database.isError - Indicates if there was an error fetching database data.
 * @property {boolean} database.showLoader - Indicates if the database fetch operation is in progress.
 * @property {boolean} database.isEmpty - Indicates if no databases are available for the selected project.
 *
 * @property {object} user - Contains information about users and the selected user.
 * @property {object} user.options - An object containing available user options with access to the selected database.
 * @property {Record<string, ProjectUser>} user.options.options - A map of user IDs to their details.
 * @property {string[]} user.options.sortedKeys - A sorted list of user IDs.
 * @property {string | undefined} user.selected - The currently selected user ID.
 * @property {function} user.setSelected - Function to update the selected user.
 * @property {boolean} user.isError - Indicates if there was an error fetching user data.
 * @property {boolean} user.showLoader - Indicates if the user data fetch operation is in progress.
 *
 * @property {object} apiKey - Contains information about API keys and the selected API key.
 * @property {object} apiKey.options - An object containing available API key options for the selected user.
 * @property {Record<string, ApiKey>} apiKey.options.options - A map of API key names to their details.
 * @property {string[]} apiKey.options.sortedKeys - A sorted list of API key names.
 * @property {string | undefined} apiKey.selected - The currently selected API key name.
 * @property {function} apiKey.setSelected - Function to update the selected API key.
 * @property {boolean} apiKey.isError - Indicates if there was an error fetching API key data.
 * @property {boolean} apiKey.showLoader - Indicates if the API key fetch operation is in progress.
 * @property {boolean} apiKey.isEmpty - Indicates if no API keys are available for the selected user.
 *
 * @property {string} connectionString - Contains information about the connection string.
 * @property {string} connectionString.text - The constructed connection string based on the selected project, database, user, and API key.
 * @property {boolean} connectionString.showLoader - Indicates if the connection string generation is in progress.
 */
export const useBuildConnectionString = (
  projectId: string,
  isDialogOpen: boolean
) => {
  const [selectedProject, setSelectedProject] = useState<string>(
    isDialogOpen ? projectId : ""
  );
  const [selectedApiKey, setSelectedApiKey] = useState<string | undefined>(
    undefined
  );
  const [selectedDatabase, setSelectedDatabase] = useState<string | undefined>(
    undefined
  );
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined
  );

  const {
    data: projects,
    showLoader: showLoaderProject,
    isError: isErrorProject,
    emptyData: isEmptyProject,
  } = useGetUserProjects(isDialogOpen);

  const projectOptions = useMemo(() => {
    const options: Record<string, Project> = {};
    const sortedKeys: string[] = [];

    projects?.forEach((project) => {
      options[project.id] = project;
      sortedKeys.push(project.id);
    });

    return { options, sortedKeys };
  }, [projects]);

  const {
    data: databases,
    showLoader: showLoaderDatabase,
    isError: isErrorDatabase,
    emptyData: isEmptyDatabase,
  } = useGetProjectDatabases(selectedProject, undefined, 5);

  const databaseOptions = useMemo(() => {
    const options: Record<string, Database> = {};
    const sortedKeys: string[] = [];

    databases?.forEach((database) => {
      options[database.name] = database;
      sortedKeys.push(database.name);
    });

    if (sortedKeys.length) {
      setSelectedDatabase(sortedKeys[0]);
    }

    return { options, sortedKeys };
  }, [databases]);

  const {
    data: users,
    showLoader: showLoaderUser,
    isError: isErrorUser,
  } = useGetProjectUsers(selectedProject, 5);

  const userOptions = useMemo(() => {
    if (!users) return { options: {}, sortedKeys: [] };

    const options: Record<string, ProjectUser> = {};
    const sortedKeys: string[] = [];

    Object.entries(users)
      .filter(([key, user]) => {
        const isAdmin = user.is_admin === 1;
        const hasRoleOnSelectedDatabase = user.roles.some(
          (role) => role.database === selectedDatabase || role.database === "*"
        );

        return isAdmin || hasRoleOnSelectedDatabase;
      })
      .forEach(([key, user]) => {
        options[key] = user;
        sortedKeys.push(key);
      });

    sortedKeys.sort((a, b) => a.localeCompare(b));

    if (sortedKeys.length) {
      setSelectedUser(sortedKeys[0]);
    }

    return { options, sortedKeys };
  }, [users, selectedDatabase]);

  const {
    data: projectApiKeys,
    showLoader: showLoaderApiKey,
    isError: isErrorApiKey,
    emptyData: isEmptyProjectApiKey,
  } = useGetProjectApiKey(selectedProject, 5);

  const apiKeyOptions = useMemo(() => {
    const options: Record<string, ApiKey> = {};
    const sortedKeys: string[] = [];

    projectApiKeys
      ?.filter((apiKey) => apiKey.username === selectedUser)
      .forEach((apiKey) => {
        options[apiKey.key] = apiKey;
        sortedKeys.push(apiKey.key);
      });

    if (sortedKeys.length) {
      setSelectedApiKey(sortedKeys[0]);
    }

    return { options, sortedKeys };
  }, [projectApiKeys, selectedUser]);

  const isEmptyApiKey = !showLoaderApiKey && apiKeyOptions.sortedKeys.length === 0

  const { connectionStringApiKey, showLoader: showLoaderConnectionString } =
    useGetProjectInfo(selectedProject);

  const connectionString = useMemo(() => {
    const [connStr] = connectionStringApiKey?.split("?") ?? [];
    return isErrorProject ||
      isEmptyProject ||
      isErrorDatabase ||
      isEmptyDatabase ||
      isErrorApiKey ||
      isEmptyApiKey
      ? ""
      : `${connStr}/${selectedDatabase}?apikey=${selectedApiKey}`;
  }, [
    connectionStringApiKey,
    selectedDatabase,
    selectedApiKey,
    isErrorProject,
    isEmptyProject,
    isErrorDatabase,
    isEmptyDatabase,
    isErrorApiKey,
    isEmptyApiKey,
  ]);

  return {
    project: {
      options: projectOptions,
      selected: selectedProject,
      setSelected: setSelectedProject,
      isError: isErrorProject,
      showLoader: showLoaderProject,
      isEmpty: isEmptyProject,
    },
    database: {
      options: databaseOptions,
      selected: selectedDatabase,
      setSelected: setSelectedDatabase,
      isError: isErrorDatabase,
      showLoader: showLoaderDatabase,
      isEmpty: isEmptyDatabase,
    },
    user: {
      options: userOptions,
      selected: selectedUser,
      setSelected: setSelectedUser,
      isError: isErrorUser,
      showLoader: showLoaderUser,
    },
    apiKey: {
      options: apiKeyOptions,
      selected: selectedApiKey,
      setSelected: setSelectedApiKey,
      isError: isErrorApiKey,
      showLoader: showLoaderApiKey,
      isEmpty: isEmptyApiKey,
    },
    connectionString: {
      text: connectionString,
      showLoader: showLoaderConnectionString,
    },
  };
};
